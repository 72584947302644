<template>
  <base-view-layout v-if="activity && activity.questions">
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back">
        <router-link :to="{ name: 'Activities' }">
          <img src="@/assets/images/icons/ico-chev-left--70L.svg">
        </router-link>
      </li>
      <li class="breadcrumb__nav-item">
        <router-link :to="{ name: 'Activities' }">{{$t('activitiesedit.breadcrumb')}}</router-link>
      </li>
      <li class="breadcrumb__nav-item">{{$t('activitiesedit.breadcrumbnavitem')}}</li>
    </template>

    <template #title>{{$t('activitiesedit.titleedit')}}</template>
    <template #subtitle>{{$t('activitiesedit.subtitleedit')}}</template>

    <activities-form v-if="activity.id" :activity="activity" @submit-activity="updateActivity($event)">
      <template #finishButton>{{$t('activitiesedit.btnupdate')}}</template>
    </activities-form>
  </base-view-layout>
</template>

<script>
import { inject, reactive } from 'vue'
import { useRoute } from 'vue-router'
import ActivitiesForm from '@/components/ActivitiesForm'
import BaseViewLayout from '@/components/BaseViewLayout'

export default {
  name: 'ActivitiesEdit',
  components: { BaseViewLayout, ActivitiesForm },
  methods: {
    async updateActivity (activity) {
      activity.questions = activity.questions.map(question => question.id)
      // activity.is_public = this.$store.state.user.role_id === 1
      await this.axios.patch(`activities/${this.$route.params.activityId}`, { activity })
      alert(this.$t('activitiesedit.successmsg'))
      this.$router.push({ name: 'Activities' })
    }
  },
  setup () {
    const axios = inject('$axios')
    const cloneObj = inject('$utils').cloneObj
    const route = useRoute()
    const activity = reactive({})

    axios.get(`activities/${route.params.activityId}`).then(res => {
      Object.keys(res.data.activity).forEach(key => activity[key] = cloneObj(res.data.activity[key]))
    })
    axios.get(`activities/${route.params.activityId}/questions`).then(res => {
      activity.questions = res.data.questions
    })

    return {
      activity,
      axios
    }
  }
}
</script>
